@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .slider {
  position: relative;
  background: white;
  box-shadow: 0 10px 20px -10px rgba (0, 0, 0, 0.2);
  overflow: hidden;
}
.slide {
  display: flex;
  align-items: center;
  animation: slideshow 15s linear infinite;
}

@keyframes slideshow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slider::before,
.slider::after {
  height: 100px;
  width: 200px;
  position: absolute;
  content: "";
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 2;
}
.slider::before {
  left: 0;
  top: 0;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.ss {
  position: -webkit-sticky;
} */

.wrapper {
  position: relative;
  /* display: flex; */
  overflow: hidden;
}

.wrapper .item {
  /* display: flex; */
}

.wrapper:hover .item {
  animation-play-state: paused;
}

/* .wrapper::before,
.wrapper::after {
  height: 100px;
  width: 200px;
  position: absolute;
  content: "";
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 2;
} */

/* .wrapper::before {
  left: 0;
  top: 0;
}
.wrapper::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
} */
/* 
@keyframes animate {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100px);
  }
} */

@media only screen and (max-width: 600px) {
  /* .wrapper::before,
  .wrapper::after {
    height: 100px;
    width: 25px;
    position: absolute;
    content: "";
    background: linear-gradient(
      to right,
      white 0%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 2;
  }

  .wrapper::before {
    left: 0;
    top: 0;
  }
  .wrapper::after {
    right: 0;
    top: 0;
    transform: rotateZ(100deg);
  } */
}

/* @media only screen and (max-width: 600px) {
  .item {
    width: 200px;
  }
}

@media only screen and (min-width: 600px) {
  .item {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) {
  .item {
    width: 200px;
  }
}

@media only screen and (min-width: 992px) {
  .item {
    width: 200px;
  }
} */

.highlight-text {
  background: linear-gradient(to right, #ffffff 0%, #f8857c 60%, #fa5d5b 100%);
  color: white;
  padding: 1rem 6rem;
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
  font-weight: 700;
  font-size: 5rem;
  display: inline-block;
}

@keyframes spin-clockwise {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin-counter {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}

.animate-spin-clockwise {
  animation: spin-clockwise 8s linear infinite;
  transform-origin: center;
}

.animate-spin-counter {
  animation: spin-counter 8s linear infinite;
  transform-origin: center;
}

.bar {
  background-color: #5CAEFF;
  border-radius: 9999px;
  opacity: 0.6;
  transition: all 0.3 ease;
}

.bar:hover {
  opacity: 1;
}

.bar.active {
  opacity: 1;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.progress-stripes {
  width: 100%;
  height: 100%;
  background-color: #FE8000; /* Color base sólido */
  background-image: repeating-linear-gradient(
    -45deg,
    #FF9730,
    #FF9730 10px,
    #FE8000 10px,
    #FE8000 20px
  );
  background-position: 0 0;
  animation: scroll-stripes 10s linear infinite;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

@keyframes scroll-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 400px 0;
  }
}